import * as math from 'mathjs'
const _ = require('lodash')
const dayjs = require('dayjs')

export const isNaValue = (v) => {
  const naValues = [null, undefined, 'NA', 'NaN', -Infinity, Infinity, '']
  return naValues.includes(v) || isNaN(v)
}

/** Handler Na Action */
export const handleNaAction = (sliceArr, naAction) => {
  sliceArr = _.cloneDeep(sliceArr)
  if (naAction == 'bfill') {
    for (let i = sliceArr.length - 1; i >= 0; i--) {
      let fillValue = sliceArr[i + 1] == undefined ? 0 : sliceArr[i + 1][1]
      if (isNaValue(sliceArr[i][1])) sliceArr[i][1] = fillValue
    }
  } else if (naAction == 'ffill') {
    for (let i = 0; i < sliceArr.length; i++) {
      let fillValue = sliceArr[i - 1] == undefined ? 0 : sliceArr[i - 1][1]
      if (isNaValue(sliceArr[i][1])) sliceArr[i][1] = fillValue
    }
  } else if (naAction == 'zero') {
    for (let i = 0; i < sliceArr.length; i++) {
      if (isNaValue(sliceArr[i][1])) sliceArr[i][1] = 0
    }
  } else {
    sliceArr = sliceArr.filter((i) => !isNaValue(i[1]))
  }
  return sliceArr
}

/** Format Date */
export const formatDate = (data) => {
  return dayjs(data).format('YYYY-MM-DD HH:mm:ss')
}

/** Standardize Data */
export const standardizeData = (data, dataStd, dataMean) => {
  return data.map((item) => (item - dataMean) / dataStd)
}

/**
 * Build Single LSTM lookback matrix
 * @returns [[1,2,3,4], [2,3,4,5], [3,4,4,6]...]
 */
export const buildSingleLSTMMatrix = (data, lookback) => {
  const endIndex = data.length - lookback
  let result = []
  for (let i = 0; i < endIndex; i++) {
    result.push(data.slice(i, i + lookback))
  }
  return result
}

/** Convert 1D array to 2D array */
export const reshape2DArray = (array1d) => {
  return array1d.map((first) => [first])
}

/** Convert 2D array to 3D array */
export const reshape3DArray = (array2d) => {
  return array2d.map((second) => second.map((third) => [third]))
}

/** De-Standardize Data */
export const destandardizeData = (data, dataStd, dataMean) => {
  var destandardizeData = data.map((item) => item * dataStd + dataMean)
  return destandardizeData
}

export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = window.atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

export const readFilesBase64 = (files) => {
  return Promise.all(files.map((file) => getFileBase64(file)))
}

export const getFileContent = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = function (e) {
      resolve(e.target.result)
    }
    reader.onerror = function (e) {
      reject(e)
    }
    reader.readAsText(file)
  })
}

export const getFileBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function () {
      resolve(reader.result)
    }
    reader.onerror = function (e) {
      reject(e)
    }
    reader.readAsDataURL(file)
  })
}

/** Standardize feature data */
export const standardizeFeaturesData = (data, splitedData) => {
  if (!splitedData) {
    return data.map((el) => {
      let dataStd = math.std(el)
      let dataMean = math.mean(el)
      let dataStandardize = standardizeData(el, dataStd, dataMean)
      return dataStandardize
    })
  } else {
    let result = []
    for (let i in data) {
      let dataStd = math.std(data[i])
      let dataMean = math.mean(data[i])
      let dataStandardize = standardizeData(splitedData[i], dataStd, dataMean)
      result.push(dataStandardize)
    }
    return result
  }
}

export const buildMultiLSTMMatrix = (data, lookback) => {
  let result = data.map((el) => {
    return buildSingleLSTMMatrix(el, lookback)
  })
  return result
}

export const transformFeatures = (data) => {
  let result = []
  for (let i = 0; i < data[0].length; i++) {
    let temp1 = []
    for (let j = 0; j < data[0][1].length; j++) {
      let temp2 = []
      for (let k = 0; k < data.length; k++) {
        temp2.push(data[k][i][j])
      }
      temp1.push(temp2)
    }
    result.push(temp1)
  }
  return result
}

export const convertFeatures = (data, lookback) => {
  let arr = data.map((el) => el.slice(lookback, el.length))
  let result = []
  for (let i = 0; i < arr[0].length; i++) {
    let temp = []
    for (let j = 0; j < arr.length; j++) {
      temp.push(arr[j][i])
    }
    result.push(temp)
  }
  return result
}
